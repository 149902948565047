.generateId {
  position: relative;
  text-align: center;
  padding: 0.25rem 0;
  margin-left: 1.5rem;
  margin-bottom: 2rem !important;
  display: flex;
  width: 100%;
}

.generateId > span {
  margin-right: 1rem;
}

.generateId textarea {
  width: 240px;
}

.listLigne {
  list-style: none;
  padding: 0;
}

.listLigne li {
  position: relative;
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: calc(100% - 33px - 10px);
}
.firstLigne {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.editing {
  background: #E1F0FB;
  color: #3293E8;
}

.confirmed {
  background: #ECFAEF;
  color: #2BBC47;
}

.pending {
  background: #EDEDED;
  color: #111;
}

.iconEye{
  background: #000;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

/* .editIdIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2rem;
  z-index: 5;
} */
.editIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -43px;
  z-index: 5;
  cursor: pointer;
}
.textCaracteristiques{
  font-size:12px;
}
.customLigne {
  margin-bottom: 0 !important;
  margin-left: 8px;
}
@media only screen and (max-width: 992px) {
  .firstLigne {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }
  .customLigne {
    margin-left: 0;
  }
}
@media only screen and (max-width: 576px) {
  .firstLigne {
    justify-content: center !important;
  }
  .customLigne {
    margin-left: 8px;
  }
  .editIdIcon {
    right: 0;
  }
}