.lotValue input {
  font-size: 0.75rem;
  height: 1.5rem;
  text-align: center;
}

.smallTitle {
  text-align: center;
  display: block;
  margin-left: 0.75rem;
}
