.title{
  text-align: center;
  color: #014289;
  margin-bottom: 2rem !important;
}

.submit{
  text-align: center;
  margin-top: 1rem;
}

.bt {
  background-color: #2bbc47 !important;
  width: 200px;
  height: 52px;
  border-radius: 15px;
  border-color: #2bbc47;
  font-size: 1rem;
}
.buttonFull {
  width: 100% !important;
}
.searchIcon {
  padding: 0 0 0.3125rem 0.625rem;
  background: #EDEDED;
  margin-top: 0.5rem;
  width: 2.25rem;
  height: auto;
}

.groupChargement {
  position: relative;
  margin-left: -5px;
}

.plusChargement {
  position: absolute;
  right: -2rem;
  top: 0.5rem;
  padding: 0.25rem;
  color: #fff;
  background: #2BBC47;
  cursor: pointer;
}

.plusChargementTutorial {
  position: absolute;
  right: -3.5rem;
  top: 0.5rem;
  padding: 0.25rem;
}

.lableChargement {
  list-style: none;
  padding: 0.5rem 2rem;
  text-align: right;
}

.lableChargement li {
  height: 2rem;
  margin-bottom: 0.625rem;
  align-items: end;
  text-align: right;
  display: grid;
  justify-content: end;
}

.inputChargement {
  list-style: none;
  background: #EDEDED;
  border-radius: 0.75rem;
  position: relative;
}

.inputChargement {
  padding: 0.5rem 0.5rem;
}

.inputChargement li {
  min-height: 2.6rem;
}

.titleDetail {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 1.1rem;
}

.deleteItem {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: 0.125rem 0.375rem;
  background: #F44B4B;
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
}