.historyContainer {
    height: 500px;
    overflow: scroll;
    cursor: move;
    position: relative;
}

.historyContainer::-webkit-scrollbar{
    display:none;
}

.historyContainer.disableScroll {
    overflow: hidden;
}

.wheelActive:after {
    background: #F1862D;
}

.wheelActive span {
    color: #F1862D;
}

.fakeWheel {
    text-align: center;
    position: relative;
    margin: 0.5rem 0;
    cursor: pointer;
    height: 2.1rem;
    width: 16rem;
}

.topWheel, .botWheel {
    text-align: center;
    margin: 0.5rem 0;
    height: 2.1rem;
    width: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background: #FAFAFA;
}

.topWheel {
    top: -1rem
}

.botWheel {
    bottom: -1rem;
}

.topWheel span, .botWheel span {
    display: block;
    width: 7rem;
    height: 2px;
    background: #bababa;
}

.filledItem:after {
    width: 10rem;
}

.filledItem span {
    display: none;
}

/*.title {*/
/*  text-align: center;*/
/*  color: #014289;*/
/*  margin-bottom: 2rem !important;*/
/*}*/

/*.submit {*/
/*  text-align: center;*/
/*  margin-top: 1rem;*/
/*}*/

/*.bt {*/
/*  width: 200px;*/
/*  height: 52px;*/
/*  border-radius: 15px;*/
/*  margin: auto;*/
/*  display: block;*/
/*}*/

/*.bt:focus {*/
/*  width: 200px;*/
/*  height: 52px;*/
/*  border-radius: 15px;*/
/*}*/

/*.bt:hover {*/
/*  border-radius: 15px;*/
/*}*/

/*.bt.btBlue {*/
/*  border-color: #014289;*/
/*  background-color: #014289;*/
/*}*/

/*.bt.btGreen {*/
/*  border-color: #2bbc47;*/
/*  background-color: #2bbc47;*/
/*}*/

/*.bt.btGreen[disabled] {*/
/*  border-color: #b3b3b3;*/
/*  background: #f5f5f5;*/
/*  color: #2bbc47;*/
/*}*/

/*.searchIcon {*/
/*  padding: 0 0 0.3125rem 0.625rem;*/
/*  background: #EDEDED;*/
/*  margin-top: 0.5rem;*/
/*  width: 2.25rem;*/
/*  height: auto;*/
/*}*/

/*.trajetTab {*/
/*  text-align: center;*/
/*  margin: 0 auto 2rem;*/
/*  display: block;*/
/*}*/

/*.labelTrajet {*/
/*  list-style: none;*/
/*  padding: 1rem 2rem;*/
/*  text-align: right;*/
/*}*/

/*.labelTrajet li {*/
/*  height: 2.75rem;*/
/*  display: flex;*/
/*  flex-direction: row-reverse;*/
/*  align-items: center;*/
/*  text-align: right;*/
/*}*/

/*.labelDistance {*/
/*  list-style: none;*/
/*  padding: 1rem 2rem;*/
/*  text-align: right;*/
/*}*/

/*.inputTrajet,*/
/*.inputDistance {*/
/*  list-style: none;*/
/*  background: #EDEDED;*/
/*  border-radius: 0.75rem;*/
/*}*/

/*.inputTrajet {*/
/*  padding: 1rem 0.5rem;*/
/*}*/

/*.inputTrajet li,*/
/*.inputDistance li {*/
/*  height: 2.75rem;*/
/*  align-items: center;*/
/*  text-align: right;*/
/*}*/

/*.inputDistance {*/
/*  padding: 0.15rem 0.5rem;*/
/*}*/

/*.inputDistance li {*/
/*  display: grid;*/
/*}*/

/*.inputDistance li div {*/
/*  margin-bottom: 0;*/
/*}*/

/*.editIcon {*/
/*  padding: 0;*/
/*  background: #EDEDED;*/
/*  width: 1.625rem;*/
/*  height: auto;*/
/*  margin-top: -0.75rem;*/
/*}*/

/*.formAu {*/
/*  margin-left: 25px;*/
/*}*/

/*.paddingLeft {*/
/*  padding-left: 80px;*/
/*}*/

/*.inputGroupCustom span {*/
/*  display: flex !important;*/
/*  align-items: center !important;*/
/*}*/

/*.flexAlignCenter {*/
/*  display: flex !important;*/
/*  align-items: center !important;*/
/*}*/

/*.paddingLeftRow {*/
/*  padding-left: 40px;*/
/*}*/

/*.marginBottomTitle {*/
/*  margin-bottom: 0 !important;*/
/*  margin-right: 8px;*/
/*}*/

/*.fullWidth {*/
/*  width: 100%;*/
/*  margin-bottom: 0 !important;*/
/*}*/

/*.currency {*/
/*  margin-left: 10px;*/
/*  padding-top: 8px;*/
/*}*/

/*.fontWeightBold {*/
/*  font-weight: bold;*/
/*}*/

/*.flexStartRowReverse {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*}*/

/*.formPlage {*/
/*  background: #EDEDED;*/
/*  height: 33px;*/
/*  margin-bottom: 0 !important;*/
/*  margin-left: 32px;*/
/*  margin-top: 10px;*/
/*}*/

/*.formPlageRight {*/
/*  margin-left: 20px;*/
/*  background: #EDEDED;*/
/*  height: 33px;*/
/*  margin-bottom: 0 !important;*/
/*  margin-top: 10px;*/
/*}*/

/*.labelTrajetPlage {*/
/*  list-style: none;*/
/*  padding: 1rem 2rem;*/
/*  text-align: right;*/
/*  padding-top: 0;*/
/*  margin-top: 10px;*/
/*}*/

/*.frequenceRevision {*/
/*  margin-left: 32px;*/
/*}*/

/*.heightRow {*/
/*  height: 58px;*/
/*}*/

/*.centerCol {*/
/*  direction: flex;*/
/*  justify-content: center;*/
/*  flex-direction: column;*/
/*}*/

/*.fontSize div {*/
/*  font-size: 14px !important;*/
/*}*/

/*.fontSize span {*/
/*  font-size: 14px !important;*/
/*}*/

/*.fontSize li {*/
/*  font-size: 14px !important;*/
/*}*/

/*.labelTrajetFrequence {*/
/*  list-style: none;*/
/*  padding: 1rem 2rem;*/
/*  text-align: right;*/
/*}*/

/*.prixContract {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*}*/
