.LotsGroup > div {
  padding-left: 0;
}

.LotsGroup .editAble {
  position: relative;
}

.LotsGroup .editAble:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 70%;
  left: 15%;
  bottom: 0;
  background: #8ecaff;
  /*animation-name: blinking;*/
  /*animation-duration: 2s;*/
  /*animation-iteration-count: infinite;*/
}

.LotsGroup input {
  text-align: center;
}
