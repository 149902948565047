.id {
  text-align: center;
}

.edit {
  margin-left: 105px;
  width: 34px;
  margin-top: -5px;
  height: 34px;
}

.bt {
  width: 200px;
  height: 52px;
  border-radius: 15px;
  color: white;
}
.btBlue {
  border-color: #014289;
  background-color: #014289;
}
