.dayProgress {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    height: 18px;
    width: 18px;
    background: linear-gradient(to right, black 99.99%, transparent), linear-gradient(to bottom, black 99.99%, transparent), linear-gradient(to right, black 99.99%, transparent), linear-gradient(to bottom, black 99.99%, transparent);
    background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
    background-repeat: no-repeat;
    background-position: -18px 0px, 17px -18px, 18px 17px, 0px 17px;
}