.popupWrap {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(256,256,256,0.6);
  text-align: center;
  overflow: hidden;
}

.popupContent {
  display: inline-block;
  min-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ddd;
  border-radius: 1.25rem;
}

.popupHeader {
  padding: 1rem 1.5rem;
  text-align: center;
  font-size: 28px;
  line-height: 2;
}

.popupBody {
  padding: 3rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5715;
  word-wrap: break-word;
  min-height: 5rem;
}

.popupFooter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.625rem 0.625rem 2rem;
  text-align: center;
  background: 0 0;
}

.groupButton {
  display: flex;
  min-width: 15rem;
}

.okButton {
  background: #014289;
  border-radius: 0.75rem;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem;
  margin: 0 0.5rem;
  text-align: center;
  width: 100%;
  border: none;
  cursor: pointer;
}

.cancelButton {
  background: #EDEDED;
  border-radius: 0.75rem;
  color: #111;
  font-size: 1rem;
  padding: 0.75rem;
  margin: 0 0.5rem;
  text-align: center;
  width: 100%;
  border: none;
  cursor: pointer;
}

.detailButton{
  background-color: #EDEDED;
  border-radius: 0.75rem;
  color: #111;
  font-size: 1rem;
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  width: 100px;
  margin-bottom: 20px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.detail{
  border: 1px solid #5E5E5E;
  border-radius: 10px; 
  margin: 10px; 
  text-align: start; 
  padding: 10px;
  height: 100px;
  overflow-y: scroll;
}
