
.btRemove {
  background-color: transparent;
  border-color: transparent;
}

.btRemove:hover {
  background-color: transparent;
  border-color: transparent;
}

.btRemove:focus {
  background-color: transparent;
  border-color: transparent;
}

.jourList {
  width: 100%;
  height: 173px;
  background-color: #DDDDDD;
  border-radius: 8px;
  font-size: 12px;
  padding: 8px 0;
  overflow: scroll;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  scrollbar-width: none;

}

.jourList::-webkit-scrollbar {
  display: none;
}

.addedLineTransporter {
  background-color: #EDEDED;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  padding: 10px 20px;
}

.inputTransporter {
  background: #ededed;
  border-width: 0 0 1px 0;
  border-color: #BCBCBC;
  border-bottom: 1px solid #BCBCBC;
  border-radius: 0;
}

.btSearch {
  width: 27px;
  height: 27px;
  border-color: #000000;
  background-color: #000000;
  border-radius: 5px;
  font-size: 14px;
  color: white;
}

.btSearch:hover {
  background-color: #000000;
  border-color: #000000;
  color: white;
}

.btSearch:focus {
  background-color: #000000;
  border-color: #000000;
  color: white;
}

.bt {
  width: 100%;
  height: 52px;
  border-radius: 15px;
  margin: 20px 0;
}

.bt:disabled {
  color: #fff;
}

.bt:disabled:hover {
  color: #fff;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgb(200, 200, 200);
  display: flex;
}

.modalContainer {
  width: 1112px;
  height: 797px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  padding: 25px;
}


.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.list_tag {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%
}

.title_modal {
  text-align: center;
}

.title_modal .subtitle {
  font-size: 20px;
  color: #000;
}

.title_modal span {
  font-size: 28px;
  color: #000;
}

.modal_search {
  display: flex;
  margin-top: 12px;
}

.searchBox {
  border: 1px solid #014289;
  border-radius: 0.75rem;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  width: 25rem;
  display: flex;
  align-items: center;
}

.tag_container {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 6px;
}

.tag_container .tag {
  height: 30px;
  margin: 5px;
  padding: 5px 6px;
  border-radius: 3px;
  background: #E1F0FB;
  display: flex;
  position: relative;
  align-items: center;
  color: #333;
  cursor: default;
}

.tableLine {
  width: 100%;
  margin-top: 3rem;
  border-spacing: 0;
}

.tableLine tbody {
  background: #F5F6F9;
  margin-top: 1rem;
  padding: 0.5rem;
}

.tableLine th {
  padding: 0.5rem 0.25rem 1.25rem;
  text-align: center;

}

.tableLine td {
  padding: 0.5rem 0.25rem;
  text-align: center;

}

.tableLine tbody tr {
  cursor: pointer;
  border-bottom: 2px solid #374063;
}

thead tr:first-child th:first-child {
  border-radius: 0.6em 0 0 0;
}

thead tr:first-child th:last-child {
  border-radius: 0 0.6em 0 0;
}

tbody tr:last-child td:first-child {
  border-radius: 0 0 0 0.6em;
}

tbody tr:last-child td:last-child {
  border-radius: 0 0 0.6em 0;
  border-bottom: 0px solid #374063;
}

.modalfooter {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.popupfooter {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.searchbtn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.ant-modal-content {
  border-radius: 30px;
}

.btpopup {
  width: 127px;
  height: 52px;
  border-radius: 15px;
}


.numberContainer div {
  margin: auto;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberContainer div span {
  font-size: 9px;
  font-weight: bold;
}

.numberContainer div.selectedPrimary span {
  color: #FFFFFF;
}

.numberContainer div.selectedSecondary span {
  color: #000000;
}

.dateContainer {
  text-align: center;
  font-size: 6px;
}

.current {
  border: 1px solid #3293E8 !important;
  background-color: #3293E8 !important;
  color: white !important;
}

.selectedPrimary {
  border: 1px solid #F1862D !important;
  background-color: #F1862D !important;
  color: white !important;
}

.selectedSecondary {
  border: 1px solid #FAECDF !important;
  background-color: #FAECDF !important;
  color: black !important;
}

.disabled {
  cursor: not-allowed !important;
  border: none;
  background-color: transparent;
  color: #662E2E2E;
}


.inputTrajet, .inputDistance {
  list-style: none;
  background: #EDEDED;
  border-radius: 0.75rem;
}

.inputTrajet {
  padding: 1rem 0.5rem;
}

.inputTrajet li, .inputDistance li {
  height: 2.75rem;
  align-items: center;
  text-align: right;
  display: grid;
}

.labelTrajet {
  list-style: none;
  padding-left: 0;
}

.labelTrajet li {
  height: 2.75rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  text-align: right;
  font-size: 12px !important;
  font-weight: bold;
}

.fullWidth {
  width: 100%;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btGreen {
  border-color: #2bbc47;
  background-color: #2bbc47;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-confirm {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 8px;

}

.btn-delete-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.div-ajouter-par {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
}

.time-row {
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
}

.time-container {
  background: #DDDDDD;
  border-radius: 10px;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.centerVerticalCheckBox {
  margin: auto 0;
}

.centerVerticalCheckBox span {
  font-size: 0.75rem;
}

.centerVerticalCheckBox span:last-child {
  padding-left: 0.25rem;
}

.bigTitle {
  font-size: 14px !important;
}

.timeChoice .smallTitle {
  font-size: 0.75rem;
}

.smallTitle {
  font-size: 0.8rem;
}

.input-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.groupLots {
  display: flex;
  margin-top: 1px;
  align-items: flex-end;
}

.jourHeader {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  height: 1.75rem;
  background: #ddd;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  align-items: center;
  text-align: center;
}

.addedListDate {
  text-align: center;
  align-items: center;
}

.addedListDate > div {
  padding: 0.25rem 0;
  line-height: 1.25rem;
}

.addedListDate > div:nth-child(2), .addedListDate > div:nth-child(3) {
  border-left: 1px solid #aaa;
}

.jourList {
  width: 100%;
  height: 173px;
  background-color: #DDDDDD;
  border-radius: 8px;
  font-size: 12px;
  padding: 8px 0;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.jourList::-webkit-scrollbar {
  display: none;
}

.btRemove {
  cursor: pointer;
}

.addForm{
  background-color: #ededed;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 1rem;
}

.rowChoixTransporteurItem {
  width: 100%;
  align-items: center;
  background-color: #EDEDED;
  padding: 15px;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.textDisable span {
  color: gray !important;
}

.btConfirm {
  width: 10rem;
  height: 2rem;
  display: block;
  margin: 1.5rem auto 0.5rem;
  background-color: #2bbc47;
  border-color: #2bbc47;
  border-radius: 1rem;
}

.btConfirm:hover, .btConfirm:focus {
  background-color: #2bbc47 ;
  border-color: #2bbc47;
}

.listDemande {
  display: block;
  background-color: #DDDDDD;
  border-radius: 12px;
  padding: 5px 10px;
  margin-bottom: 0.75rem;
}

.headerList {
  background-color: #DDDDDD;
  border-radius: 12px;
  height: 35px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
}

.quickView footer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.625rem;
}

.quickView footer button {
  background: transparent;
  border: none;
  border-radius: 0.75rem;
  margin-left: 0.5rem;
}

.quickView footer button:hover{
  background: transparent;
}

.display-pp-rate{    
  display: flex;    
  justify-content: space-between;    
  background-color: #EDEDED;    
  border-radius: 10px;    
  padding: 5px 10px;    
  margin-bottom: 20px;  
  font-size: 14px;
}
