.title{
  text-align: center;
  color: #014289;
  margin-bottom: 2rem !important;
}

.submit{
  text-align: center;
  margin-top: 1rem;
}

.bt {
  background-color: #2bbc47 !important;
  width: 200px;
  height: 52px;
  border-radius: 15px;
  border-color: #2bbc47;
  font-size: 1rem;
}
.buttonFull {
  width: 100% !important;
}

.plageOn {
  height: 1.525rem;
}

.groupBudget {
  position: relative;
  margin-left: -5px;
}

.plusBudget {
  position: absolute;
  right: -2rem;
  top: 0.5rem;
  padding: 0.25rem;
  color: #fff;
  background: #2BBC47;
  cursor: pointer;
}

.lableBudget {
  list-style: none;
  padding: 0.5rem 2rem;
  text-align: right;
}

.lableBudget li {
  height: 2rem;
  margin-bottom: 0.625rem;
  align-items: end;
  text-align: right;
  display: grid;
}

.inputBudget {
  list-style: none;
  background: #EDEDED;
  border-radius: 0.75rem;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 1rem ;
}

.inputBudget li {
  min-height: 2.6rem;
}

.inputBudget input {
  border-bottom: 1px solid #ccc
}

.titleDetail {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 1.1rem;
}

.groupCoutAdd {
  margin-right: 0.625rem;
}

.addButton {
  background: #014289;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
  font-size: 1rem;
  border-radius: 0.75rem;
}

.listCout {
  margin-top: 1rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
  background: #EDEDED;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.listCout div {
  background: transparent !important;
}

.listCout .deleteButton {
  position: absolute;
  right: -3rem;
  padding: 0.125rem 0.175rem;
  background: #F44B4B !important;
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
}

