.bt{
    background-color: #2bbc47 !important;
    width: 200px;
    height: 52px;
    border-radius: 15px;
    border-color: #2bbc47;
    font-size: 1rem;
}

.bt:focus{
    background-color: #2bbc47;
    width: 200px;
    height: 52px;
    border-radius: 15px;
    border-color: #2bbc47;
}
.bt:hover{
    border-radius: 15px;
    border-color: #2bbc47;
}

.trajetTab {
    text-align: center;
    margin: 0 auto 2rem;
    display: block;
}

.labelTrajet {
    list-style: none;
    padding: 1.1rem 2rem;
    text-align: right;
}

.labelTrajet li {
    display: flex;
    height: 2.75rem;
    align-items: center;
    flex-direction: row-reverse;
    text-align: right;
}

.d-flex {
    display: flex;
    align-items: center;
}

.labelDistance {
    list-style: none;
    padding: 1rem 2rem;
    text-align: right;
}

.labelDistance li {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: right;
}

.inputTrajet, .inputDistance {
    list-style: none;
    background: #EDEDED;
    border-radius: 0.75rem;
}

.inputTrajet li, .inputDistance li {
    height: 2.75rem;
    align-items: center;
    text-align: right;
    display: grid;
}

.inputDistance {
    padding: 0.15rem 0.5rem;
}

.inputDistance li {
    display: grid;
}

.inputDistance li div {
    margin-bottom: 0;
}

.inputTrajet {
    padding: 1rem 0.5rem;
}

.distanceEts input{
    background: #E1F0FB !important;
}
.distanceEts{
    background: #E1F0FB !important;
}

.editIcon {
    padding: 0;
    background: #EDEDED;
    width: 1.625rem;
    height: auto;
    margin-top: -0.75rem;
}

.trajetMap {
    width: 40rem;
    margin: auto
}

.hideTrajet {
    display: none;
}

.hideMap {
    opacity: 0;
    position: absolute;
}

.submit{
    text-align: center;
    margin-top: 1rem;
}

/* Pick Location */

.searchIcon {
    padding: 0;
    background: #EDEDED;
    width: 1.625rem;
    height: auto;
    cursor: pointer;
}
