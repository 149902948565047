.customInputNumber {
  /* padding: 0.25rem 1rem;
  background: #ededed; */
  border-radius: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.25rem;
  display: flex;
  max-width: 100%;
}

.customInputNumber > span {
  padding: 0;
  border: none;
}

.customInputNumber span {
  margin: 0;
  background: transparent;
  display: flex;
  align-items: flex-end;
}

.customInputNumber input {
  border-width: 0 0 1px 0;
  border-color: #BCBCBC;
  border-style: solid;
  padding: 0.3125rem;
  border-radius: 0;
}

.customInputNumber.borderedNone input{
  border-width: 0;
}

.customInputNumber input:focus {
  border-color: #bcbcbc;
  border-right: 0;
  box-shadow: none;
}

.customInputNumber input:hover {
  border-right-width: 0;
}

.customInputNumber div {
  border: none;
  height: 100%;
  background: transparent !important;
  border-radius: 0;
}

.customInputNumber .suffix {
  padding-left: 0.5rem;
}

@media (max-width: 1024px) {
  .customInputNumber {
    width: auto;
  }
}

