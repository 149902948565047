.title {
  text-align: center;
  color: #014289;
  margin-bottom: 2rem !important;
}

.submit {
  text-align: center;
  margin-top: 1rem;
}

.bt {
  width: 200px;
  height: 52px;
  border-radius: 15px;
  margin: auto;
  display: block;
}

.bt:focus {
  width: 200px;
  height: 52px;
  border-radius: 15px;
}

.bt:hover {
  border-radius: 15px;
}

.btBlue {
  border-color: #014289 !important;
  background-color: #014289 !important;
}

.btGreen {
  background-color: #2BBC47;
  border-color: #2BBC47;
  width: 162px;
  height: 33px;
}

.searchIcon {
  padding: 0 0 0.3125rem 0.625rem;
  background: #EDEDED;
  margin-top: 0.5rem;
  width: 2.25rem;
  height: auto;
}

.trajetTab {
  text-align: center;
  margin: 0 auto 2rem;
  display: block;
}

.labelTrajet {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
}

.labelTrajet li {
  height: 2.75rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: right;
}

.labelDistance {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
}

.inputTrajet,
.inputDistance {
  list-style: none;
  background: #EDEDED;
  border-radius: 0.75rem;
}

.inputTrajet {
  padding: 1rem 0.5rem;
}

.inputTrajet li,
.inputDistance li {
  height: 2.75rem;
  align-items: center;
  text-align: right;
}

.inputDistance {
  padding: 0.15rem 0.5rem;
}

.inputDistance li {
  display: grid;
}

.inputDistance li div {
  margin-bottom: 0;
}

.editIcon {
  padding: 0;
  background: #EDEDED;
  width: 1.625rem;
  height: auto;
  margin-top: -0.75rem;
}

.formAu {
  margin-left: 25px;
}

.paddingLeft {
  padding-left: 80px;
}

.inputGroupCustom span {
  display: flex !important;
  align-items: center !important;
}

.flexAlignCenter {
  display: flex !important;
  align-items: center !important;
}

.paddingLeftRow {
  padding-left: 40px;
}

.marginBottomTitle {
  margin-bottom: 0 !important;
  margin-right: 8px;
}

.fullWidth {
  width: 100%;
  margin-bottom: 0 !important;
}

.currency {
  margin-left: 10px;
  padding-top: 8px;
}

.fontWeightBold {
  font-weight: bold;
}

.flexStartRowReverse {
  display: flex;
  justify-content: flex-end;
}

.formPlage {
  background: #EDEDED;
  height: 33px;
  margin-bottom: 0 !important;
  margin-left: 32px;
  margin-top: 10px;
}

.formPlageRight {
  margin-left: 20px;
  background: #EDEDED;
  height: 33px;
  margin-bottom: 0 !important;
  margin-top: 10px;
}

.labelTrajetPlage {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
  padding-top: 0;
  margin-top: 10px;
}

.frequenceRevision {
  margin-left: 32px;
}

.heightRow {
  height: 58px;
}

.centerCol {
  direction: flex;
  justify-content: center;
  flex-direction: column;
}

.fontSize div {
  font-size: 14px !important;
}

.fontSize span {
  font-size: 14px !important;
}

.fontSize li {
  font-size: 14px !important;
}

.labelTrajetFrequence {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
}

.prixContract {
  display: flex;
  justify-content: flex-end;
}

.title_modal {
  text-align: center;
}

.title_modal .subtitle {
  font-size: 20px;
  color: #000;
}

.title_modal span {
  font-size: 28px;
  color: #000;
}

.searchbtn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.modal_search {
  margin-top: 12px;
}

.searchBox {
  border: 1px solid #014289;
  border-radius: 0.75rem;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  width: 25rem;
  display: flex;
  align-items: center;
}

.tag_container {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 6px;
}

.tag_container .tag {
  height: 30px;
  margin: 5px;
  padding: 5px 6px;
  border-radius: 3px;
  background: #E1F0FB;
  display: flex;
  position: relative;
  align-items: center;
  color: #333;
  cursor: default;
}

.tableLine {
  width: 100%;
  margin-top: 3rem;
  border-spacing: 0;
}

.tableLine tbody {
  background: #F5F6F9;
  margin-top: 1rem;
  padding: 0.5rem;
}

.tableLine th {
  padding: 0.5rem 0.25rem 1.25rem;
  text-align: center;

}

.tableLine td {
  padding: 0.5rem 0.25rem;
  text-align: center;

}

.tableLine tbody tr {
  cursor: pointer;
  border-bottom: 2px solid #374063;
}

thead tr:first-child th:first-child {
  border-radius: 0.6em 0 0 0;
}

thead tr:first-child th:last-child {
  border-radius: 0 0.6em 0 0;
}

tbody tr:last-child td:first-child {
  border-radius: 0 0 0 0.6em;
}

tbody tr:last-child td:last-child {
  border-radius: 0 0 0.6em 0;
  border-bottom: 0 solid #374063;
}

.selected {
  background: #014289;
}

.selected td {
  color: #fff;
}

.notSelect {
  background: #F5F6F9;
}

.notSelect td {
  color: #333;
}

.submitButton {
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  background: #014289;
  width: 12rem;
  margin-top: 1rem
}

.modalSearch h3 {
  font-size: 1.75rem;
  text-align: center;
}

.modalSearch .subtitle {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.modalSearch .searchHeader {
  display: flex;
  align-items: center;
}

.tableLine tbody tr:last-child td:first-child {
  border-radius: 0;
}

.list_tag {
  display: flex;
}

.result{
  padding-left:20.5rem;
  font-size: 12px;
  color: #F1862D;
  margin-top:8px;
}