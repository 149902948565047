/* Custom Input */
.customInput {
  padding: 0.25rem 1rem;
  background: #ededed;
}

.customInput .ant-input-suffix {
  position: relative;
  margin-left: 0.5rem;
  text-align: left;
  display: flex;
  background: transparent;
  padding: 0;
  border: none;
  align-items: center;
}

.customInput .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
}

.customInput .ant-input[disabled]:hover {
  border-right-width: 0;
}

.customInput .ant-input-affix-wrapper {
  padding: 0;
  border: none;
  align-items: center;
  background: transparent;
}

.customInput input {
  background: #ededed;
  border-width: 0 0 1px 0;
  border-color: #BCBCBC;
  border-bottom: 1px solid #BCBCBC !important;
  padding: 0.125rem;
  border-radius: 0;
  display: inline-block;
  line-height: 1.875;
}

.customInput input:focus {
  border-color: #bcbcbc;
  border-right: 0;
  box-shadow: none;
}

.customInput input:hover {
  border-right-width: 0;
}

.ant-input-clear-icon {
  position: absolute;
  left: -1.25rem;
  z-index: 5;
}
