.menuContainer {

}

.addForm {
  padding: 0.75rem;
  border-radius: 0.75rem;
  background: #ededed;
  position: relative;
  width: 100%;
  font-size: 0.875rem;
}

.addForm .smallLabel {
  margin-bottom: 0;
}

.addForm .smallLabel.rendezVous label span {
  font-size: 0.875rem;
}

.addForm .smallLabel label {
  font-size: 0.75rem;
}

.btGreen, .btGreen:hover {
  width: 100%;
  height: 3.5rem;
  display: block;
  margin: 1rem auto;
  background-color: #2bbc47;
  border-color: #2bbc47;
  border-radius: 1rem;
}

.btAddDetail, .btAddDetail:hover {
  width: 100%;
  height: 3.5rem;
  display: block;
  margin: 1rem auto;
  background-color: #014289;
  border-color: #014289;
  border-radius: 1rem;
}

.btRemoveContainer {
  border: none;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.btRemove {
  border: none;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background: transparent;
  border-radius: 1rem;
}

.btConfirm {
  width: 10rem;
  height: 2rem;
  display: block;
  margin: 1.5rem auto 0.5rem;
  background-color: #2bbc47;
  border-color: #2bbc47;
  border-radius: 1rem;
}

.btRemove:hover, .btConfirm:hover {
  background: transparent;
}

.listDemande {
  display: block;
  background-color: #ededed;
  border-radius: 12px;
  padding: 0;
  margin: 0.75rem 0;
}

.headerList {
  background-color: #ededed;
  border-radius: 12px;
  height: 33px;
  justify-content: space-between;
  padding: 0 0 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.quickView footer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.625rem 0.625rem;
}

.quickView footer button {
  background: transparent;
  border: none;
  border-radius: 0.75rem;
  margin-left: 0.5rem;
}

.preview {
  margin: 0 10px 10px;
}
