.headerCalendar {
  display: flex;
  justify-content: flex-end;
}

.headerCalendar > div {
  margin-left: 0.625rem;
}

.yearCalendar {
  /* min-width: 1100px; */
  margin-top: 32px;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.wrapRowCalendar {
  width: 100%;
  display: grid;
  gap: 0 16px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  justify-content: center;
}
.colCalendar {
  min-width: 257px;
}

.calendarWrapper {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.dayContainer {
  aspect-ratio: 1/1;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.numberContainer {
  height: 60%;
  text-align: center;
}

.dayContainer span {
  font-size: 0.625rem;
  font-weight: bold;
  padding: 0.25rem 0.375rem;
  border-radius: 3px;
  border-color: #111;
  border-style: solid;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateContainer {
  text-align: center;
  font-size: 7px;
  margin-top: -0.125rem;
}

.current {
  border: 2px solid #3293E8 !important;
}

.selectedPrimary {
  border: 1px solid #F1862D !important;
  background-color: #F1862D !important;
  color: white !important;
}

.selectedSecondary {
  border: 1px solid #FAECDF !important;
  background-color: #FAECDF !important;
  color: black !important;
}

.disabled {
  cursor: not-allowed !important;
  border: none;
  background-color: transparent;
  color: #662E2E2E;
}

.headerContainer {
  width: 400px;
  height: 33px;
  background-color: #EDEDED;
  border-radius: 10px;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 14px;
}

.headerContainer div {
  margin: auto 0;
}

.dateSelect {
  width: 6rem;
  font-size: 0.875rem;
  background: #EDEDED;
  border-radius: 0.75rem;
}

.dateSelectPrevious {
  margin-left: 10px;
  background: transparent;
  border: none;
  box-shadow: transparent !important;
  color: black;
}

.dateSelectNext {
  background: transparent;
  border: none;
  box-shadow: transparent !important;
  color: black;
}

/* .calendarDateContainer span {
    font-size: 6px;
} */

@media (max-width: 1500px) {
  .wrapRowCalendar {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 1024px) {
  .wrapRowCalendar {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
