.title{
  text-align: center;
  color: #014289;
  margin-bottom: 2rem !important;
}

.submit{
  text-align: center;
  margin-top: 1rem;
}

.bt{
  background-color: #2bbc47 !important;
  width: 200px;
  height: 52px;
  border-radius: 15px;
  border-color: #2bbc47;
  font-size: 1rem;
}

.bt:focus{
  background-color: #2bbc47;
  width: 200px;
  height: 52px;
  border-radius: 15px;
  border-color: #2bbc47;
}
.bt:hover{
  border-radius: 15px;
  border-color: #2bbc47;
}

.searchIcon {
  padding: 0 0 0.3125rem 0.625rem;
  background: #EDEDED;
  margin-top: 0.5rem;
  width: 2.25rem;
  height: auto;
}

.trajetTab {
  text-align: center;
  margin: 0 auto 2rem;
  display: block;
}

.labelTrajet {
  list-style: none;
  padding: 1.1rem 2rem;
  text-align: right;
}
.labelTrajetIndexPage {
  padding: .5rem 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.labelTrajetIndexPage.formContent{
  background-color: #EDEDED;
  border-radius: 12px;
}
.labelTrajetIndexPage.form li {
  height: 53px;
}
.labelTrajetIndexPage.left {
  align-items: flex-end;
}
.labelTrajetIndexPage.left li{
  text-align: right;
}
.labelTrajetIndexPage li {
  display: flex;
  align-items: center;
}
.labelTrajet li {
  height: 2.75rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: right;
}

.labelDistance {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
}

.labelDistance li {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: right;
}

.hideTrajet {
  display: none;
}

.inputTrajet, .inputDistance {
  list-style: none;
  background: #EDEDED;
  border-radius: 0.75rem;
}

.inputTrajet {
  padding: 1rem 0.5rem;
}

.inputTrajet li, .inputDistance li {
  height: 2.75rem;
  align-items: center;
  text-align: right;
  display: grid;
}

.inputDistance {
  padding: 0.15rem 0.5rem;
}

.inputDistance li {
  display: grid;
}

.inputDistance li div {
  margin-bottom: 0;
}

.highlight {
  font-size: 1rem;
  font-weight: bold;
}

.editIcon {
  padding: 0;
  background: #EDEDED;
  width: 1.625rem;
  height: auto;
  margin-top: -0.75rem;
}

.formAu{
  margin-left:25px;
}

.paddingLeft{
  padding-left:80px;
}

.inputGroupCustom span {
  display: flex !important;
  align-items: center !important;
}
.flexAlignCenter{
  display: flex !important;
  align-items: center !important;
}
.paddingLeftRow{
  padding-left:40px;
}
.marginBottomTitle{
  margin-bottom:0 !important;
  margin-right:8px;
}
.fullWidth{
  width: 100%;
  margin-bottom:0 !important;
}
.currency{
  margin-left:10px;
  padding-top:8px;
}
.fontWeightBold{
  font-weight: bold;
}
.flexStartRowReverse{
  display:flex;
  justify-content: flex-end;
}
.formPlage{
  background: #EDEDED;
  height:33px;
  margin-bottom:0 !important;
  margin-right:32px;

}
.formPlageRight{
  background: #EDEDED;
  height:33px;
  margin-bottom:0 !important;

}

.labelTrajetPlage {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
  padding-top:0;
  margin-top:10px;
}
.frequenceRevision{
  margin-left: 0;
  margin-bottom: 0 !important;
  padding: .5rem 0;
}
.heightRow{
  height: 58px;
}

.centerCol{
  direction: flex;
  justify-content: center;
  flex-direction: column;
}
.fontSize div{
  font-size: 14px !important;
}
.fontSize span{
  font-size: 14px !important;
}

.fontSize li{
  font-size: 14px !important;
}

.labelTrajetFrequence {
  list-style: none;
  padding: 1rem 2rem;
  text-align: right;
}
.prixContract{
  display: flex;
  justify-content: flex-end;
}
.distanceEts input{
  background: #E1F0FB !important;
}
.distanceEts{
  background: #E1F0FB !important;
}
.titlePlateformes{
  text-align:right;
  padding-right:10px;
}
.titlePlateformes label{
  font-size:24px;
  font-weight:bold;
  white-space: nowrap;
}

.wrapFormPlateformes {
  padding: 0 2rem;
}

.formPlateformes {
  margin-top: 0.5rem;
  align-items: center;
}

.labelContact {
  font-weight: bold;
  text-align: right;
  padding-right: 1rem;
}

.contactInput {
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
}

.contactError {
  background: #FAECDF;
}

.contactError input {
  color: #F1862D;
  border-bottom: 1px solid #F1862D !important;
  padding: 0;
  border-radius: 0;
}

.contactValid {
  background: #ECFAEF;
  padding: 0.5rem 1rem;
}

.contactValid input {
  color: #2BBC47;
  border-bottom: 1px solid #2BBC47 !important;
  padding: 0;
  border-radius: 0;
}

.contactDisable {
  background: #e2e2e2;
  padding: 0.5rem 1rem;
}

.contactDisable input {
  border-bottom: 1px solid #cfcfcf !important;
  padding: 0;
  border-radius: 0;
}

@media only screen and (max-width: 1200px) {
  .labelTrajet {
    padding: 1rem;
  }

  .wrapFormPlateformes {
    padding: 0 .5rem;
  }
}

.ant-picker-clear {
  display: none;
}
