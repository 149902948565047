.dateInput {
  margin-left: -1rem;
  font-size: 0.75rem;
}

.groupLots {
  display: flex;
  margin-left: -2rem;
  margin-top: 1px;
  align-items: center;
}

.groupLots > div {
  max-width: 3.2rem;
}

.lotValue {
  font-size: 0.75rem;
  height: 1rem;
  min-width: 2.2rem;
}

.addLotsButton {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  border-color: #014289;
  background-color: #014289;
}

.jourHeader {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  height: 1.75rem;
  background: #ddd;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  align-items: center;
  text-align: center;
}

.jourList {
  margin-top: 0.5rem;
  width: 100%;
  height: 11rem;
  background-color: #DDDDDD;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  padding: 0.5rem 0;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.jourList::-webkit-scrollbar {
  display: none;
}

.jourSelection {
  margin-top: 2rem;
}

.addedListDate {
  text-align: center;
  align-items: center;
}

.addedListDate > div {
  padding: 0.25rem 0;
  line-height: 1.25rem;
}

.addedListDate > div:nth-child(2), .addedListDate > div:nth-child(3) {
  border-left: 1px solid #aaa;
}

.removeBtn {
  display: flex;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
  margin: auto;
  background: transparent;
}
