.header {
  height: 120px;
  padding: 0 15px;
  color: #ffffffa6;
  line-height: 64px;
  background: #fff;
  text-align: center;
}

.headerContainer {
  max-width: 1710px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.flexHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* width: calc(100% - 9rem); */
  /* max-width: 1200px; */
}
.mainNav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  min-width: 0;
}

.mainNav a {
  padding: 1rem;
  margin-right: 1.15rem;
  color: #014289;
  font-weight: 600;
  white-space: nowrap;
}
.mainNav a:last-child {
  margin-right: 0;
}
.mainNav a.active {
  color: #F1862D;
}

.mainNav a:hover {
  cursor: pointer;
  color: #F1862D;
}

.rightNav {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.groupIndicator {
  margin-right: 40px;
}

.indicatorTutorial {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F6F6F9;
  border-radius: 100%;
  cursor: pointer;
  z-index: 10;
}

.indicatorTutorial svg {
  padding-top: 5px;
}

.notification {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F6F6F9;
  border-radius: 100%;
  margin-right: 40px;
  cursor: pointer;
}

.notification:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.675rem;
  background: #F1862D;
  border-radius: 100%;
}

.userMenu {
  color: #000;
}

.userIcon {
  font-size: 1.15rem;
  padding: 0.75rem;
  background: #F6F6F9;
  border-radius: 100%;
}

.btn-create-user{
  background-color: #f1862e;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  border: 1px solid #f1862e;
}

.btn-create-user:hover{
  border: 1px solid #f1862e;
  color: #014289;
}

.mobileNav {
  display: flex;
  flex: 1;
  min-width: 0;
  margin-left: 2rem;
}
.mobileRightNav {
  display: none;
}
.iconMenu {
  display: none;
  cursor: pointer;
  font-size: 32px;
  color: #014289;
}

@media only screen and (max-width: 1400px) {
  .mobileNav {
    margin-left: 0;
  }
  .mainNav a {
    margin-right: 0;
  }
  .notification {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1270px) {
  .mobileNav {
    flex-direction: column;
    position: absolute;
    top: 120px;
    right: 0;
    min-width: 350px;
    padding: .5rem 1rem;
    z-index: 999;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: .15s ease-in-out;
  }
  .mobileNav.active {
    visibility: visible;
    opacity: 1;
  }
  .mobileNav .mainNav {
    flex-direction: column;
    align-items: flex-start;
  }
  .mainNav a {
    margin-right: 0;
  }
  .rightNav {
    margin-right: 2rem;
  }
  .mobileRightNav {
    display: none;
  }
  .iconMenu {
    display: block;
  }
  .notification {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .rightNav {
    display: none;
  }
  .mobileRightNav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
  }
}
