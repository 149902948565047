.customSelect {
  background: #EDEDED;
  border-radius: 0.75rem;
  height: 53px;
  align-items: center;
  display: flex;
  padding: 0 0.25rem;
}

.customSelect .ant-select-selector > * {
  font-size: 1rem;
}

.customSelect .ant-select .ant-select-selector input.ant-select-selection-search-input {
  opacity: 1 !important;
  margin: 0 !important;
  border-bottom: 1px solid #BCBCBC;
}

.customSelect .ant-select-selector .ant-select-selection-item {
  margin-right: 1.125rem;
  text-align: left;
  font-size: 14px;
}
