@import '~antd/dist/antd.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  background: rgba(255, 255, 255);
  z-index: 10;
  color: #111;
  overflow: hidden;
}

.container {
  max-width: 1410px; /* may be change later */
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

.ant-input, .ant-form-item-label > label {
  font-size: 14px;
  font-weight: bold;
}

.ant-form-horizontal .ant-form-item {
  margin-bottom: 10px;
  align-items: center;
}

/* customCheckbox */

.customCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2bbc47;
  border-color: #2bbc47;
  font-size: 1rem;
}

.customCheckbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.customCheckbox .ant-checkbox:hover .ant-checkbox-inner,
.customCheckbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2bbc47;
  font-size: 1rem;

}
.customCheckbox.ant-checkbox-wrapper-checked {
  color: #2bbc47;
  font-size: 1rem;
}
.customCheckbox.ant-checkbox-wrapper {
  font-size: 1rem;
}


/* customCheckbox with text */

.cb .ant-checkbox {
    top: 0.15em;
}

.cb .ant-checkbox .ant-checkbox-inner {
    width: 13px;
    height: 13px;
    border-radius: 3px;
    border-color: black !important;
}

.cb .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2BBC47 !important;
    border-color: #2BBC47 !important;
}

.cb .ant-checkbox+span {
    color: black;
}

.cb .ant-checkbox.ant-checkbox-checked+span {
    color: #2BBC47;
}


/* customDatePicker */

.customDatePicker input{
  border-bottom: 1px solid #BCBCBC;
}

.ant-form-item-required:before {
  display: none !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  min-height: auto;
}

/*labelItem*/

.labelItem{
  text-align: right;
  font-size: 1rem;
}

/**/

.dropdown-one{
  border: none;
  background: none;
  box-shadow: none;
}

/**/

.input-one {
  border: none;
  background: none;
  border-bottom: 1px solid #BCBCBC;

}

.input-one:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
}

/**/
.box-one{
  background: #ededed;
  border-radius: 10px;
  padding: 10px;
}

/**/
.input-number-one{
  background: #ededed;
  border: none;
  box-shadow: none;
  border-radius: 5px;
}

.ant-input-number-group-addon{
  background: none;
  border: none;
  border-radius: 5px;
}

/**/

.slick-dots {
  z-index: 200;
}

.customInputNumber .ant-input-number-status-error input,
.customInput .ant-input-affix-wrapper-status-error input,
.customInput .ant-input-status-error input {
    border-bottom: 1px solid #ff4d4f !important;
}

.customInputNumber .ant-input-number-group-addon:last-child {
  padding: 0;
}

.ant-input-number-handler-wrap {
  display: none;
}

/* Common */
.common-title {
  text-align: center;
  color: #014289 !important;
  margin-top: 3rem;
  margin-bottom: 3rem !important;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}

.custom-datepicker-timer {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #BCBCBC;
  padding: 0 2px;
  width: 48px;
}
input:focus {
  outline: none;
  box-shadow: none;
}

.addDemande .ant-space-vertical {
  gap: 0 !important;
}

.addDemande .ant-radio-inner {
  border-radius: 3px;
  width: 0.8125rem;
  height: 0.8125rem;
}

.addDemande .ant-radio-wrapper-checked {
  color: #2bbc47;
}

.addDemande .ant-radio-checked .ant-radio-inner {
  background: #2bbc47;
  border-color: #2bbc47;
}

.addDemande .ant-radio-inner:after {
  background: transparent;
  border-radius: 0;
}

.addDemande .ant-radio-checked .ant-radio-inner:after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  content: " ";
  display: table;
  opacity: 1;
  position: absolute;
  transform: rotate(45deg);
  transition: none;
  height: 9.14285714px;
  left: 100%;
  top: 60%;
  width: 5.71428571px;
}

.addDemande .subType .ant-form-item-control-input {
  align-items: baseline;
}

.addDemande .ant-radio-disabled + span {
  color: inherit;
}

.addDemande .ant-checkbox .ant-checkbox-inner, .previewDemande .ant-checkbox .ant-checkbox-inner{
  background-color: initial!important;
  border-color: #000!important;
  border-radius: 3px;
  height: 13px;
  width: 13px;
  font-size: 0.875rem;
}

.addDemande .ant-checkbox-checked .ant-checkbox-inner, .previewDemande .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2bbc47!important;
  border-color: #2bbc47!important;
}

.addDemande .ant-checkbox.ant-checkbox-checked+span, .previewDemande .ant-checkbox.ant-checkbox-checked+span {
  color: #2bbc47;
}

.addDemande .smallTitle,
.previewDemande .smallTitle {
  font-size: 0.75rem;
}

.addDemande .bigTitle {
  font-size: 0.875rem;
  display: block;
}

.addDemande .MoisJour label.ant-checkbox-wrapper, .previewDemande .MoisJour label.ant-checkbox-wrapper{
  width: 100%;
  margin-left: 0;
}

.addDemande .ant-form-item label span, .previewDemande .ant-form-item label span {
  font-size: 0.75rem;
  color: inherit;
}

.previewDemande label.customCheckbox span, .previewDemande label.ant-checkbox-wrapper span{
  color: inherit;
}

.addDemande label.ant-checkbox-wrapper span, .previewDemande label.ant-checkbox-wrapper span{
  font-size: 0.75rem;
}

.SemaineJour .customInputNumber input {
  height: 1rem;
}

.SemaineJour .ant-input-number-disabled {
  background: transparent;
}

.SemaineJour label.customCheckbox span {
  font-size: 0.75rem;
}

.MoisAutre .ant-checkbox-wrapper {
  display: flex;
  margin-left: 0;
}

.opacitySelect .ant-select .ant-select-selector {
  background: transparent;
  border-radius: 1rem;
}

.ant-table-cell-fix-right {
  background: transparent;
}

#choixtransporteur .ant-row {
  justify-content: center;
  align-items: center;
}

#choixtransporteur .ant-input-number input {
  height: auto;
  padding: 0 .3125rem;
}

#choixtransporteur .codeName .ant-form-item-label > label {
  align-items: flex-end;
}

.wheelDate {
  text-align: center;
  position: relative;
  margin: 0.5rem 0;
  cursor: pointer;
  height: 2.1rem;
  width: 16rem;
}

.wheelDate span {
  position: relative;
  background: #FAFAFA;
  z-index: 2;
  padding: 0 0.5rem;
}

.wheelDate:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 15rem;
  height: 1px;
  background: #111;
  z-index: 1;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* style wheel */
/*.swiper-slide-active .wheelDate:after{*/
/*  background: #F1862D;*/
/*}*/

/*.swiper-slide-active span {*/
/*  color: #F1862D;*/
/*}*/

/*.swiper-slide-prev, .swiper-slide-next {*/
/*  opacity: 0.9;*/
/*  !*font-size: 0.9em;*!*/
/*}*/

/*.wheelIndex2 {*/
/*  opacity: 0.8;*/
/*  !*font-size: 0.8em;*!*/
/*}*/

/*.wheelIndex3 {*/
/*  opacity: 0.7;*/
/*  !*font-size: 0.7em;*!*/
/*}*/

/*.wheelIndex4 {*/
/*  opacity: 0.6;*/
/*  !*font-size: 0.6em;*!*/
/*}*/

/*.wheelIndex5 {*/
/*  opacity: 0.5;*/
/*  !*font-size: 0.5em;*!*/
/*}*/

/*.wheelIndex5 span {*/
/*  display: none;*/
/*}*/

/*.swiper-slide-next .wheelDate:after, .swiper-slide-prev .wheelDate:after {*/
/*  background: #333;*/
/*  width: 13rem;*/
/*}*/

/*.wheelIndex2 .wheelDate:after {*/
/*  background: #555;*/
/*  width: 12rem;*/
/*}*/

/*.wheelIndex3 .wheelDate:after {*/
/*  background: #777;*/
/*  width: 11rem;*/
/*}*/

/*.wheelIndex4 .wheelDate:after {*/
/*  background: #777;*/
/*  width: 9rem;*/
/*}*/

/*.wheelIndex5 .wheelDate:after {*/
/*  background: #777;*/
/*  width: 5rem;*/
/*}*/


