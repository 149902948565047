.tab-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}
.tab{
  display: flex;
  flex-direction: row;
}
.tab button {
  padding: 5px 15px;
  border-radius: 15px 15px 0 0;
  width: 191px;
  height: 41px;
}

.body{
  background-color: white;
  padding: 30px 30px;
}
.header {
  display: flex;
}
.guide{
  border-radius: 0 0 10px 10px;
  background-color: #FAECDF;
  padding: 15px;
}

.tableLine {
  width: 100%;
  margin-top: 3rem;
}

.tableLine tbody {
  background: #F5F6F9;
  border-radius: 0.75rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.tableLine th {
  padding: 0.5rem 0.25rem 1.25rem;
  text-align: center;
}

.tableLine td {
  padding: 0.5rem 0.25rem;
  text-align: center;

}

.tableLine td input {
  background: transparent;
}

.selected td {
  background-color: #3a8fe5;
}

.selectedTd {
  background-color: #3a8fe5;
}

.tableLine tbody tr {
  border-bottom: 2px solid #374063;
}

.borderth th {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}

.trWithCopy td {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: none!important;
}



.action img{
  cursor: pointer;
}
.bt {
  width: 200px;
  height: 52px;
  border-radius: 15px;
}

.bt:focus {
  width: 200px;
  height: 52px;
  border-radius: 15px;
}

.bt:hover {
  border-radius: 15px;
}
.bt.btGreen {
  border-color: #2bbc47;
  background-color: #2bbc47;
  color: white;
}

.error {
  background-color: #FAE8E8;
}
